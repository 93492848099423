<template>
  <div>
    <div class="container">
      <el-card class="box-card">
        <div class="container-left flex">
          <div class="event-box">
            <div v-for="(type, index) in eventTypeOptions" :key="index"
                 :class="pageForm.warningEventTypeId == type.id ?'active': ''"
                 @click.stop="changeEvent(type)">
              {{ type.name }}
            </div>
          </div>
          <div style="display: flex; flex-direction: column; padding-left: 10px;">
            <el-radio-group v-model="pageForm.buildingId" @change="changeBuilding">
              <el-radio-button size="large" border v-for="(building,index) in buildingOptions"
                               :key="index" :label="building.id">
                {{ building.name }}
              </el-radio-button>
            </el-radio-group>
            <el-form :model="pageForm" style="margin-top: 10px;padding-left: 30px;">
              <div style="font-size: 18px;font-weight: 600; text-align: left; margin-bottom: 8px">负责人</div>
              <el-form-item label="负责人" style="padding-left: 20px">
                <el-select v-model="pageForm.head" placeholder="请选择负责人" multiple clearable style="width: 60%">
                  <el-option v-for="(item, index) in adminOptions" :key="index" :label="item.name" :value="item.id"/>
                </el-select>
              </el-form-item>
              <div style="font-size: 18px;font-weight: 600; text-align: left; margin-bottom: 8px">预警声音</div>
              <el-form-item label="预警声音：" style="padding-left: 20px" v-show="pageForm.soundId > 0">
                <el-icon :size="20" @click="playMusic">
                  <VideoPlay v-show="!playMusicChange"/>
                  <VideoPause v-show="playMusicChange"/>
                  <audio ref="audioRef" src="" @ended="musicEnd"></audio>
                </el-icon>
              </el-form-item>
              <el-form-item style="padding-left: 20px">
                <el-radio-group v-model="pageForm.soundId" class="ml-4" style="margin-right: 30px;"
                                v-if="audioOptions.length" @change="musicChange">
                  <el-radio :label="item.id" size="large"
                            v-for="(item, index) in audioOptions" :key="index">
                    <span style="padding-right: 10px;">{{ item.name }}</span>
                  </el-radio>
                </el-radio-group>
                <button type="button" class="addBtn" @click.stop="handleShowUpload('0')">自定义上传</button>
              </el-form-item>
              <div style="font-size: 18px;font-weight: 600; text-align: left; margin-bottom: 8px">预警图标</div>
              <el-form-item style="padding-left: 20px">
                <el-radio-group v-model="pageForm.iconId" class="ml-4" style="margin-right: 30px;">
                  <el-radio :label="img.id" size="large" v-for="(img,index) in iconOptions" :key="index">
                    <img :src="'api'+img.filePath" style="width: 30px;height: 30px;object-fit: contain;">
                  </el-radio>
                </el-radio-group>
                <button type="button" class="addBtn" @click.stop="handleShowUpload('1')">自定义上传</button>
              </el-form-item>
              <div style="font-size: 18px;font-weight: 600; text-align: left; margin-bottom: 8px">短信设置</div>
              <el-form-item label="短信通知：" style="padding-left: 20px">
                <el-radio-group v-model="pageForm.message" class="ml-4" style="margin-right: 30px;">
                  <el-radio :label="1" size="large">是</el-radio>
                  <el-radio :label="0" size="large">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="短信人员：" style="padding-left: 20px">
                <el-checkbox v-model="pageForm.messageToBuildingAdmin">楼栋负责人</el-checkbox>
                <span style="padding:0 15px;">
                  <el-checkbox v-model="pageForm.messageToLaboratoryAdmin">实验室负责人</el-checkbox>
                </span>
                <span style="padding-right:5px;">
                <el-checkbox label="自定义" name="type" v-model="pageForm.messageToOther"></el-checkbox>
                </span>
                <el-select v-model="pageForm.other" placeholder="自定义人员" size="small" multiple
                           style="width: 700px;" clearable
                           :disabled="!pageForm.messageToOther">
                  <el-option v-for="item in adminOptions" :key="item.id" :label="item.name"
                             :value="item.id"/>
                </el-select>
              </el-form-item>
              <el-form-item class="last" style="height: 410px; padding-left: 20px" label="应急预案：">
                <editor @getText="getText" ref="editor" :content="pageForm.plan"
                        style="height: 320px;width: 100%"></editor>
              </el-form-item>
            </el-form>
            <div>
              <!--              <button class="resetBtn" @click="search">取消</button>-->
              <button type="button" class="searchBtn" @click="submit">保存</button>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!--上传弹框-->
    <el-dialog title="自定义上传"
               v-model="uploadDialogVisible" width="35%"
               class="info-div600" style="padding: 24px;text-align: left">
      <el-form ref="uploadFormRef"
               :model="uploadForm"
               :rules="uploadRules"
               label-width="120px"
               class="demo-ruleForm"
               size="default"
               status-icon>
        <el-form-item label="名称" prop="name">
          <el-input v-model="uploadForm.name"/>
        </el-form-item>
        <el-form-item label="预警声音" prop="filePath" style="padding-left: 20px" v-if="uploadForm.type == 0">
          <el-upload action="#" :show-file-list="false" :http-request="handleUpload">
            <div class="addBtn">自定义上传</div>
          </el-upload>
          <div>{{ uploadForm.filePath }}</div>
        </el-form-item>
        <el-form-item label="预警图标" prop="filePath" style="padding-left: 20px" v-if="uploadForm.type == 1">
          <el-upload action="" class="avatar-uploader" :show-file-list="false" :http-request="handleUpload">
            <img v-if="uploadForm.filePath" :src="'api'+uploadForm.filePath" class="avatar"/>
            <el-icon v-else class="avatar-uploader-icon">
              <Plus/>
            </el-icon>
          </el-upload>
        </el-form-item>
        <div class="dialog-footer marginTop30px text-right">
          <button type="button" class="qxBtn" @click="uploadDialogVisible = false">关闭</button>
          <button type="button" class="subBtn marginLeft15" @click="uploadSubmit('uploadFormRef')">提交</button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import editor from "@/components/editor.vue";
import {
  allWarningEventType,
  getAllBuilding,
  warningEventPlan,
  getSysFileListByType,
  saveMonitorSysFile, saveWarningEventPlan,
} from "@/api/monitor";
import {getAdminList, uploadFile} from "@/api/api";

export default {
  components: {
    editor
  },
  data() {
    return {
      //
      eventTypeOptions: [], // 所有事件
      buildingOptions: [], // 所有楼栋
      adminOptions: [], // 负责人选项，多选
      audioOptions: [], // 预警声音选项
      iconOptions: [], // 预警图标选项
      //
      playMusicChange: false,
      //
      pageForm: {
        id: '',
        buildingId: '', // 当前楼栋Id
        warningEventTypeId: '', //预警事件类型Id
        head: [], // 负责人
        iconId: 0,
        message: true,
        messageToBuildingAdmin: 0,
        messageToLaboratoryAdmin: 0,
        messageToOther: 0,
        other: [],
        plan: "",
        soundId: 0,
      },
      // 上传form
      uploadDialogVisible: false,
      uploadForm: {
        filePath: '',
        name: '',
        type: '',
      },
      uploadRules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        filePath: [
          {required: true, message: '请上传文件', trigger: 'blur'}
        ],
      },
    }
  },
  created() {
    this.getAdmins()
    this.getAudios()
    this.getIcons()
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const resT = await allWarningEventType()
      if (!resT.success) return this.$message.error('获取事件类型失败')
      const resB = await getAllBuilding()
      if (!resB.success) return this.$message.error('获取楼栋失败')
      this.eventTypeOptions = resT?.data || []
      this.buildingOptions = resB?.data || []
      if (this.eventTypeOptions.length && this.buildingOptions.length) {
        this.pageForm.warningEventTypeId = this.eventTypeOptions[0].id
        this.pageForm.buildingId = this.buildingOptions[0].id
        this.getEventPlan()
      }
    },
    getAdmins() {
      getAdminList().then(res => {
        this.adminOptions = res?.data || []
      })
    },
    getAudios() {
      getSysFileListByType(0).then(res => {
        this.audioOptions = res?.data || []
      })
    },
    getIcons() {
      getSysFileListByType(1).then(res => {
        this.iconOptions = res?.data || []
      })
    },
    // 切换预警事件类型
    changeEvent(type) {
      this.musicChange()
      if (this.pageForm.warningEventTypeId == type.id) return;
      this.pageForm.warningEventTypeId = type.id
      this.getEventPlan()
    },
    // 切换楼栋
    changeBuilding() {
      this.musicChange()
      this.getEventPlan()
    },
    getEventPlan: function () {
      warningEventPlan(this.pageForm.warningEventTypeId, this.pageForm.buildingId)
          .then(({success, message, data}) => {
            if (!success) return this.$message.error(message || '获取预警设置失败')
            this.pageForm = Object.assign(this.pageForm, {
              id: data?.id || '',
              head: data?.head || [],
              iconId: data?.iconId || '',
              message: Number(data.message),
              messageToBuildingAdmin: !!data.messageToBuildingAdmin,
              messageToLaboratoryAdmin: !!data.messageToLaboratoryAdmin,
              messageToOther: !!data.messageToOther,
              other: data?.other || [],
              plan: data.plan,
              soundId: data?.soundId || '',
            })
            this.$refs.editor.setText(this.pageForm.plan);
          })
    },
    //上传文件类型： 0 预警音频 1 预警图标 2 摄像头截图 3 摄像头录像
    handleShowUpload(type) {
      this.uploadForm = {
        filePath: '',
        name: '',
        type: type,
      }
      this.uploadDialogVisible = true
    },
    handleUpload(source) {
      if (this.pageForm.type == 0 && (source.file.type !== "audio/ogg" && source.file.type !== "audio/mpeg")) {
        return this.$message.error('音频格式仅支持 MP3 | OGG！')
      }
      if (this.pageForm.type == 1 && !source.file.type.startWith('image')) {
        return this.$message.error('只能上传图片')
      }
      uploadFile(source).then(res => {
        if (res.success) {
          this.uploadForm.filePath = res.data || ''
        } else {
          this.$message.error('上传失败')
        }
      })
    },
    uploadSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveMonitorSysFile(this.uploadForm).then(res => {
            if (!res.success) {
              this.$message.error(res.message || '操作失败')
              return
            }
            this.uploadDialogVisible = false
            if (this.uploadForm.type == 0) {
              this.getAudios()
            }
            if (this.uploadForm.type == 1) {
              this.getIcons()
            }
          })
        }

      })
    },
    playMusic() {
      if (!this.pageForm.soundId) return this.$message.error('请选择声音')
      const music = this.audioOptions.find(item => item.id == this.pageForm.soundId)
      this.$refs.audioRef.src = `api${music.filePath}`
      this.playMusicChange = !this.playMusicChange
      if (this.playMusicChange) {
        this.$nextTick(() => {
          this.$refs.audioRef.play()
        })
      } else {
        this.$nextTick(() => {
          this.$refs.audioRef.pause()
        })
      }
    },
    // 音频播放结束
    musicEnd() {
      this.playMusicChange = false
    },
    musicChange() {
      if (!this.$refs.audioRef) return
      this.$refs.audioRef.pause()
      this.playMusicChange = false
      this.$refs.audioRef.src = ''
    },
    // 编辑器html
    getText(html) {
      this.pageForm.plan = html
    },
    submit() {
      if(this.pageForm.head.length < 1) return this.$message.error('请选择负责人')
      if(!this.pageForm.iconId) return this.$message.error('请选择预警图标')
      if(!this.pageForm.soundId) return this.$message.error('请选择预警声音')
      this.pageForm.messageToBuildingAdmin = Number(this.pageForm.messageToBuildingAdmin)
      this.pageForm.messageToLaboratoryAdmin = Number(this.pageForm.messageToLaboratoryAdmin)
      this.pageForm.messageToOther = Number(this.pageForm.messageToOther)
      saveWarningEventPlan(this.pageForm).then(res => {
        if (res.success) {
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.message || '操作失败')
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__content {
  text-align: left;
}

::v-deep .el-radio.el-radio--large .el-radio__label {
  line-height: 0;
}

.last {
  ::v-deep .el-form-item__content {
    align-items: flex-start;
  }
}

.event-box {
  width: 120px;
  border-right: 1px solid #eee;

  & > div {
    padding: 10px;
    border-right: 2px solid #eee;

    &.active {
      color: #409eff;
      border-right: 2px solid #409eff;
    }
  }
}

::v-deep .avatar-uploader {
  .el-upload, .avatar {
    width: 50px;
    height: 50px;
  }
}
</style>